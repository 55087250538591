import {
  CaretDownOutlined,
  CaretRightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Upload,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import picture from "../../../../assets/images/home-page/Rectangle 95.png";
import { postApplyJob } from "../../../../redux/slices/applyJob";
import { getcareer } from "../../../../redux/slices/career";
import { dispatch, useSelector } from "../../../../redux/store";
import ButtonCustom from "../../../button/ButtonCustom";
import "./JobOffer.scss";
import { ModalApplyCareer } from "../../careers-page";
import { IJobOfferHomePage } from "../../../../@types/homePage";

const { Panel } = Collapse;



const text = `
A dog is a type of domesticated animal.
Known for its loyalty and faithfulness,
it can be found as a welcome guest in many households across the world.
`;

const JobOffer = ({
  title,
  content,
  image,
  subtitle,
  outstandingJobId,
  listImageUrl
}: IJobOfferHomePage) => {
  const { t } = useTranslation();
  const [arrayKey, setArrayKey] = useState<string | string[]>([]);
  const handleCollapseChange = (activeKey: string | string[]) => {
    setArrayKey(activeKey);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="containerCustom">
      <Row
        justify="space-between"
        align="middle"
        className="jobOffer"
        gutter={[
          { xs: 0, xl: 140 },
          { xs: 24, xl: 0 },
        ]}
      >
        <Col
          xs={24}
          xl={12}
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-duration="700"
        >
          <div className="jobOffer__image">
            <div className="shine-effect">
            <img src={image ? image : picture} alt="Why Choose Us" />
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          xl={12}
          data-aos="fade-down"
          data-aos-anchor-placement="top-center"
          data-aos-duration="700"
        >
          <div className="jobOffer__content">
          <h2 className="title jobOffer__content__title">
          {title ? (
            <span className="title">
              {title} <span className="fire-emoji">🔥</span>
            </span>
          ) : 'Receiving a job offer'}
        </h2>
            <h3 className="title__content jobOffer__content--title">
              {/* render html */}
              <div dangerouslySetInnerHTML={{ __html: subtitle }} />
              {/* {subtitle ? subtitle : ` Confirming your aceerptance guarantees the job is your, usually
              there are other candidates in the process at this point so ensure
              you are committed`} */}
            </h3>
          </div>
          {content.length > 0 && (
            <Collapse
              ghost
              onChange={handleCollapseChange}
              expandIconPosition="end"
              className="panelJobOffer"
              expandIcon={({ isActive }) => (!isActive ? <CaretRightOutlined /> : <CaretDownOutlined />)}
            >
              {content
                .filter(item => item &&  item.title && item.content) 
                .map((item, index) => (
                  <Panel
                    className="panelJobOffer__item"
                    header={
                      <div
                        className={
                          arrayKey.includes((index + 1).toString())
                            ? "panelJobOffer__header active"
                            : "panelJobOffer__header"
                        }
                      >
                        {item.title}
                      </div>
                    }
                    key={index + 1}
                  >
                    <p>{item.content}</p>
                  </Panel>
                ))}
            </Collapse>
          )}

          <ButtonCustom
            title={t('applyNow')}
            className="mainButton"
            onClick={showModal}
          />
        </Col>
      </Row>
      {outstandingJobId ? (
        <ModalApplyCareer
          careerCurrent={outstandingJobId.value}
          isModalOpen={isModalOpen}
          handleClose={handleClose}
        />
      ) : null}


      {/* <Modal
        width={586}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="jobApplyModal"
      >
        <Form form={form} name="formJob" onFinish={onFinish}>
          <Row className="formApplyJob">
            <Col xs={24} className="jobApplyModal__title">
            {t("homepage.headline.titleJob")}
            </Col>
            <Col xs={24}>
              <div className="formApplyJob__label">
                {t("homepage.modal.fullName")}
              </div>
              <Form.Item
                name="fullName"
                rules={[
                  { required: true, message: "Please input your fullname!" },
                ]}
              >
                <Input
                  className="formApplyJob__input"
                  placeholder={t("homepage.modal.fullName")}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                  <div className="formApplyJob__label">
                    {t("homepage.modal.birthDate")}
                  </div>
                  <Form.Item
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your date of birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="formApplyJob__input"
                      id="birthDate"
                      name="birthDate"
                      placeholder="dd/mm/yyyy"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div className="formApplyJob__label">
                    {t("homepage.modal.phoneNumber")}
                  </div>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone!",
                      },
                      {
                        pattern: /^0\d{9}$/,
                        message: "Please enter 10 numbers correctly and start with 0!",
                      },
                    ]}
                   
                  >
                     <Input  addonBefore="(+084)" className="formApplyJob__input" maxLength={10}  placeholder="_ _ _ _ _ _ _ _ _ _" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <div className="formApplyJob__label">
                {t("homepage.modal.experience")}
              </div>
              <Form.Item
                name="experience"
                rules={[
                  { required: true, message: "Please input your experience!" },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Please input a valid number!",
                  },
                ]}
              >
                <Input className="formApplyJob__input" placeholder="number" />
              </Form.Item>
            </Col>
            <Col xs={24}>
            <div className="formApplyJob__label">
                {t("homepage.modal.resume")}
              </div>
              <Upload maxCount={1}
                beforeUpload={() => false}
                fileList={arrFile}
                onChange={(file) => {
                  setArrFile(file.fileList);
                }}>
                
                <Button className="formApplyJob__upload" size="large">
                  <UploadOutlined />
                  {t("homepage.modal.upload")}
                </Button>
              </Upload>
              <div className="formApplyJob__support">Support file type: docx, pdf</div>
            </Col>
            <Col xs={24}>
              <Button
                htmlType="submit"
                size="large"
                className="formApplyJob__submit"
                onClick={handleClick} loading={loading}
              >
                               {t("homepage.modal.send")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal> */}
    </div>
  );
};

export default JobOffer;
